import React from "react";
import cn from "classnames";
import styles from "./ValueProps.module.sass";
import ScrollParallax from "../ScrollParallax";
import Image from "../../components/Image";

const items = [
  {
    title: "Restaurants can create as many deals as they like for free.",
    content:
      "We have low setup fees, no monthly charges, and no tricks. We won't even take your credit card number.",
      image:
      "/images/content/reffoCreation.png",
      alt:
      "Limitless"
  },
  {
    title: "Foodies secure the flash deals with a deposit.",
    content:
      "When a deal is purchased, we give the restaurant 100% of the deposit, minus $1 (+ transaction fees). Seriously, that's it.",
      image:
      "/images/content/creditCard.png",
      alt:
      "Deposit"
  },
  {
    title: "We transfer the deposit within 24 hours.",
    content:
      "You'll never receive an invoice from us — we only pay you! How's that for a change?",
      image:
      "/images/content/depositIcon.png",
      alt:
      "Restaurants keep the deposit minus the $1"
  },
];

const ValueProps = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage-small", styles.stage)}>
            Transparent Pricing
          </div>
          <h2 className={cn("h2", styles.title)}>
           Every flash deal that brings you a customer costs only <strong>$1</strong>.
          </h2>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div>
                <Image className={styles.icon}
                    srcSet={x.image}
                    srcSetDark={x.image}
                    alt={x.alt}
                />
              </div>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ValueProps;
