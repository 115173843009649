import * as Yup from 'yup'

const zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/

export const WaitlistSignupSchema = Yup.object().shape({    
      email: Yup.string().email('Invalid email')
      .required('Required'),
      zipcode: Yup.string().matches(zipRegExp, "Zipcode is not valid")
      .min(5, 'Must be exactly 5 characters')
      .max(5, 'Must be exactly 5 characters')
      .required('Required'),
  });