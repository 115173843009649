import React from "react";
// import Plan from "./Plan";
// import Signup from "./Signup";
// import Comment from "./Comment";
// import Faq from "./Faq";
// import Testimonials from "../../components/Testimonials";
// import Waitlists from "../../components/Waitlists";
import Class01Details from "../Class01Details";

const FAQS = () => {
    return (
        <>  
            <Class01Details />
        </>
    );
};

export default FAQS;
