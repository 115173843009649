// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCpgGHAZqU4ibzlRUv3V9aZvbVviOdElnI",
  authDomain: "reffo-000.firebaseapp.com",
  projectId: "reffo-000",
  storageBucket: "reffo-000.appspot.com",
  messagingSenderId: "1049882264426",
  appId: "1:1049882264426:web:c391b5cf6c81b93ea9db13",
  measurementId: "G-FWRQ3QJ0GC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);
// const authentication
const auth = getAuth(app);

// export {app, db}
export {app, db, auth}