import React from "react";
import cn from "classnames";
import styles from "./RestaurantsCTA.module.sass";
import Image from "../Image";
import { Link } from "react-router-dom";

const RestaurantsCTA = () => {
  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        {/* <div className={styles.preview}>
          <Image
            srcSet="/images/restCTA.png 2x"
            srcSetDark="/images/restCTA.png 2x"
            src="/images/restCTA.png"
            srcDark="/images/restCTA.png"
            alt="Dumbbell"
          />
        </div> */}
        <div className={cn("stage-small", styles.stage)}>
          Ready to sign up? 🚀
        </div>
        <h2 className={cn("h2", styles.title)}>Let’s party!</h2>
        <div className={styles.preview}>
          <Image
            srcSet="/images/restCTA.png 2x"
            srcSetDark="/images/restCTA.png 2x"
            src="/images/restCTA.png"
            srcDark="/images/restCTA.png"
            alt="Dumbbell"
          />
        </div>
        <div className={styles.text}>
        If you would like to list an offer on Reffo, you can start today. Create an account through our admin portal, add your restaurant's info, and create your first offer! <br/><br/>Note that offers will be visible on the <a href="https://app.reffo.deals">Reffo app</a> immediately, but our <strong>big launch date is June 14th, 2024</strong>.
          <div>
            <br/><Link className={cn("button", styles.button)} to="https://admin.reffo.deals">
              Get Started
            </Link>
          </div>
          <div className={styles.note}>
            <br/>We will never spam you, and we'll always protect your private information.
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantsCTA;
