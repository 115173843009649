import React, { useState } from "react";
import cn from "classnames";
import styles from "./WaitList.module.sass";
import Icon from "../Icon";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../../firebase';

import { Formik, useFormik } from 'formik';
// import * as Yup from 'yup';
import { WaitlistSignupSchema } from "./WaitlistSchema";
import ModalJoinedWaitlist from "../../components/ModalJoinedWaitlist";

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";




const WaitList = ({ placeholderEmail, 
                    placeholderZip,
                    }) => {
                      
  const [visibleModal, setVisibleModal] = useState(false);

  //Firebase auth
  const auth = getAuth();

  const onSubmit = async (values, actions, e) => {
    const result = Math.random().toString(36).substring(2,8);
    try {
      const docRef = await addDoc(collection(db, 'admin/earlyAdopters/earlyAdopters'), {
        emailAddress: values.email,
        zipcode: values.zipcode,
        isRestaurant: false,
        repName: "",
        businessName: "",
        timestamp: serverTimestamp(),
      });
      createUserWithEmailAndPassword(auth, values.email, result) 
      .then((userCredential) => {
        // Signed up 
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
      
      // createUserWithEmailAndPassword(auth, values.email, result);
      // setAppliedForEarlyAdopter(true);
      actions.resetForm();
      setVisibleModal(true);
  
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const {values, errors, touched, isSubmitting, handleBlur, handleSubmit, handleChange} = useFormik({
    initialValues: {
      email: '',
      zipcode: '',
    },
    validationSchema: WaitlistSignupSchema,
    onSubmit,
  })

  return (
    
    <div>
      <Formik>
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit(e)}}>
        {errors.email && touched.email && <small>{errors.email}</small>}
          <input
            className={styles.input}
            type="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            id="email"
            placeholder={placeholderEmail}
            key="email"
          />
          
          {errors.zipcode && touched.zipcode && <small>{errors.zipcode}</small>}
          <input
            className={styles.input}
            type="text"
            value={values.zipcode}
            onChange={handleChange}
            onBlur={handleBlur}
            name="zipcode"
            id="zipcode"
            placeholder={placeholderZip} 
            key="zcode"
          />
          

          <div className={styles.btns}>
            <button disabled={isSubmitting} className={cn("button", styles.button)} type="submit">
              <span>Register</span>
              <Icon name="arrow-right" size="10" />
            </button>
          </div>
        </form>
      </ Formik>
      <ModalJoinedWaitlist
        visible={visibleModal}
        
        onClose={() => setVisibleModal(false)}
      />
    </div>
)};

export default WaitList;
