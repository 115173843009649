import React from "react";
import cn from "classnames";
// import { Link } from "react-router-dom";
import styles from "./RestHowItWorks.module.sass";
// import Image from "../Image";
// import ScrollParallax from "../ScrollParallax";
import RestaurantInitForm from "../RestaurantInitForm";

const items = [
  "No hidden fees",
  "You have full control of deals",
  "Helps eliminate food waste",
  "Allows emergent marketing",
  "We work with independently owned restaurants",
  "We work with chains & franchises",
];

const RestHowItWorks = () => {
  return (
    <div className={styles.section}>
      
      <div className={cn("container", "formGrid", styles.container, styles.formGrid)}>
          <h2 className={cn("h2", "formTitle",  styles.formTitle, styles.title)}>
            Why restaurants love Reffo
          </h2>
        <div className={cn("formText", styles.formText, styles.wrap)}>
          <div className={styles.info}>
            We provide our services with straightforward <a href="/pricing">pricing</a>. <br/><br/>We collect <strong className={styles.green}></strong>$1 for every deal we close, or $5 per experience. That's it!
          </div> 
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li className={styles.item} key={index}>
                {x}
              </li>
            ))}
          </ul>
        </div>

        <div className={cn("formFields", styles.formFields)} >
        <RestaurantInitForm 
              placeholderBusinessName = "* Legal Business Name"
              placeholderBusinessOwner = "* Owner Name" 
              placeholderOwnerPhone = "* Owner's Phone Number"
              placeholderOwnerEmail = "* Owner's Email Address"
              placeholderAddress1 = "* Business Legal Address"
              placeholderAddress2 = "* Apartment, Suite, etc."
              placeholderCity = "* City"
              placeholderState = "* State" 
              placeholderZipcode = "* Zip Code"
            />
        </div>
      </div>
    </div>
  );
};

export default RestHowItWorks;
