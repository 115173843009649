import React from "react";
// import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./AboutUsContent.module.sass";
import CostCalculator from "../CostCalculator";
import Slider from "react-slick";
import Icon from "../Icon";
import { PopupButton } from '@typeform/embed-react'

const AboutUsContent = ({ classNameTitle, title, classNameSection, scrollToRef }) => {
  return (
    <div className={cn(classNameSection, styles.section)}>
      <div className={styles.anchor} ref={scrollToRef}></div>
      <div className={cn("container", styles.container)}>

      <div className={styles.head}>
          <h2 className={cn("h2", styles.titleHead)}>The story behind the team</h2>
          {/* <h2 className={cn("h2", styles.titleHead)}>The first 1,000 accounts <br></br>will receive a lifetime discount</h2> */}
          {/* <div className={styles.infotext}>
            Anyone who signs up for a paid account before we launch will receive a lifetime discount on all of our paid plans. The earlier you sign up, the bigger the discount. 
          </div> */}
          <div className={styles.infotext}>
            Reffo is built with love by co-founders Doug Kinnison & Kendall Totten. Though we are from Michigan, we have enjoyed living in North Carolina for more than a decade. <a href="https://grepbeat.com/2024/04/16/reffo-will-connect-hungry-diners-to-restaurants-that-are-ready-for-them/">Read more about our story on Grepbeat.</a>
          </div>
        </div>

        <div className={styles.info}>
          Food has always been something we get excited about, and we’re big supporters of local business and community. We wanted to build something that was beneficial for both restaurants and foodies alike! Reffo is launching in the Triangle of NC, which is Raleigh, Durham and Chapel Hill, as well as surrounding communities such as Cary, Garner, Holly Springs, Morrisville, and Fuquay-Varina. Our hope is that after we pilot the business model here, we can expand to other areas. <br/><br/>
          We appreciate your enthusiasm for Reffo and are always open to hearing your feedback.
        </div>

      </div>
    </div>
  );
};

export default AboutUsContent;
