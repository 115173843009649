import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Browse restaurants",
    color: "#3772FF",
    images: "/images/content/eye.svg",
    content:
      "With Reffo, you can see restaurants nearby offering deals and experiences right now.",
  },
  {
    title: "Filter results",
    color: "#9757D7",
    images: "/images/content/filter.svg",
    content:
      "What suits your mood? Tonight a BOGO appetizer, tomorrow a unique, culinary exploration from the chef!",
  },
  {
    title: "Secure your deal",
    color: "#45b26b",
    images: "/images/content/lock.svg",
    content:
      "Lock in your deal & reservation by placing a deposit which is credited towards your total bill.",
  },
  {
    title: "Feast and save",
    color: "#EA526F",
    images: "/images/content/yum.svg",
    content:
      "Arrive hungry and on time. Simply show your Reffo code to the waitstaff to receive your discount.",
  },
];

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>How it works</h2>
          <div className={styles.info}>
            When restaurants have availability, they can offer discounts to bring in diners and avoid food waste!
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                <img src={x.images} alt={`Step ${index}`} />
              </div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
