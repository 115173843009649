import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import ScrollButton from "../../../components/ScrollButton";
import Image from "../../../components/Image";
// import ScrollParallax from "../../../components/ScrollParallax";

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            Ever had a slow night? 
          </div>
          <h1 className={cn("h1", styles.title)}>
            Create a custom offer in a flash!
          </h1>
          <div className={styles.text}>
             If you are a restauranteur, Reffo is for you! Whether your reason is empty tables, too much minestrone, or a unique wine pairing experience; you decide when the time is right to increase your business! <br/><br/> With low setup fees, no new devices needed, no monthly costs, and the ability to fully customize your offers... what's not to love?
          </div>
          <div className={styles.btns}>
            <Link className={cn("button", styles.button)} to="https://admin.reffo.deals">
              Start the application
            </Link>
            <Link
              className={cn("button-stroke", styles.button)}
              to="/contact"
            >
              Contact us
            </Link>
          </div>
        </div>
        <ScrollButton
          onScroll={() =>
            scrollToRef.current.scrollIntoView({ behavior: "smooth" })
          }
          className={styles.scroll}
        />
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/food/foodOptions.png 2x"
              srcSetDark="/images/content/food/foodOptions.png 2x"
              src="/images/content/noodles.png"
              srcDark="/images/content/noodles.png"
              alt="Main"
            />
          </div>
          {/* <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            delay={600}
          >
            <img
              srcSet="/images/content/stickers/layer 10.png 2x"
              src="/images/content/stickers/layer 10.png"
              alt="Ball"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            delay={300}
          >
            <img
              srcSet="/images/content/stickers/layer 9.png 2x"
              src="/images/content/stickers/layer 11.png"
              alt="Ball"
            />
          </ScrollParallax> */}
          {/* <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            delay={600}
          >
            <img
              srcSet="/images/content/stickers/layer 10.png 2x"
              src="/images/content/stickers/layer 10.png"
              alt="Ball"
            />
          </ScrollParallax> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
