import React from "react";
import Hero from "./Hero";
// import Interesting from "./Interesting";

const TermsOfService = () => {
    return (
        <>
            <Hero />
            {/* <Interesting /> */}
        </>
    );
};

export default TermsOfService;
