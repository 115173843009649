import React, { useRef } from "react";
// import styles from "./Features.module.sass";
import Hero from "./Hero";
// import Intro from "../../components/Intro";
// import ValueProps from "../../components/ValueProps";
// import Program from "./Program";
// import About from "./About";
// import Review from "../../components/Review";
// import Clients from "../../components/Clients";
import Workouts from "../../components/RestHowItWorks";
// import Advantages from "../../components/Advantages";
// import Offer from "../../components/Offer";
import Lifestyle from "./Lifestyle";

const Philosophy = () => {
  const scrollToRef = useRef(null);

  return (
    <>
      <Hero scrollToRef={scrollToRef} />
      
      {/* <ValueProps className="section-pb" /> */}
      
      <Lifestyle />

      {/* <About /> */}
      {/* <Program /> */}

      {/* <Intro scrollToRef={scrollToRef} /> */}

      {/* <Review className="section-pb64" /> */}
      
      {/* <Clients /> */}
      <Workouts />
      
      {/* <Advantages /> */}
      {/* <Offer className="section" /> */}
    </>
  );
};

export default Philosophy;
