import React, { useState } from "react";
import cn from "classnames";
import styles from "./CostCalculator.module.sass";
import ScrollParallax from "../ScrollParallax";
import Image from "../Image";

const items = [
  {
    title: "Restaurants can create as many offers as they like for free.",
    content:
      "We have low setup fees, no membership charges, and no tricks. We won't even take your credit card number.",
      image:
      "/images/content/reffoCreation.png",
      alt:
      "Limitless"
  },
];

const CostCalculator = ({ className }) => {
  const [transferCost, setTranferCost] = useState('$.44');
  const [payout, setPayout] = useState('$3.56');
  const [selected, setSelected] = useState(5);

  function SelectDeposit(deposit){
    setSelected(deposit);
    if(deposit == 5){ setTranferCost('$.44'); 
                              setPayout('$3.56');     

    }else if(deposit == 10){ setTranferCost('$.59');
                              setPayout('$8.41');
                         
    }else if(deposit == 20){ setTranferCost('$.88');
                              setPayout('$18.12');
                         
    }else if(deposit == 50){ setTranferCost('$2.75');
                              setPayout('$47.25');
    }
    console.log(selected);
  }
  // const SelectDeposit = (depsoit) => {
  //   if(depsoit == 5){ setTranferCost('$.44'); 
  //                             setPayout('$3.56');
  //   }else if(depsoit == 10){ setTranferCost('$.59');
  //                             setPayout('$8.41');
  //   }else if(depsoit == 10){ setTranferCost('$.74');
  //                             setPayout('$13.26');
  //   }else if(depsoit == 10){ setTranferCost('$.88');
  //                             setPayout('$18.12');
  //   }
    
  // }

  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage-small", styles.stage)}>
            Cost calculator
          </div>
          <h2 className={cn("h2", styles.title)}>
           Deposits belong to you!
          </h2>
          <div className={cn("h6", styles.title)}>
           <br/>No one knows your restaurant like you do, so we give you the control to offer any kind of deals you want, whenever you want. When you create a deal, it's free. We only keep $1 from each customer's deposit. Choose a deposit amount below to see the cost breakdown.
          </div>
        </div>
        <div className={styles.list}>
          <div className={selected == 5 ? cn(styles.selected) : cn(styles.unselected)} onClick={() => SelectDeposit(5)} value="5" >$5</div>
          <div className={selected == 10 ? cn(styles.selected) : cn(styles.unselected)} onClick={() => SelectDeposit(10)} value="10" >$10</div>
          <div className={selected == 20 ? cn(styles.selected) : cn(styles.unselected)} onClick={() => SelectDeposit(20)} value="20" >$20</div>
          <div className={selected == 50 ? cn(styles.selected) : cn(styles.unselected)} onClick={() => SelectDeposit(50)} value="50" >$50</div>
        </div>

        <div className={styles.listCosts}>
          <div className={cn(styles.holder, styles.item_one)}>
            <div >
              <div className={cn(styles.holderIcon)}>
                <Image className={styles.icon}
                    srcSet="/images/content/emptyBoltDark.png"
                    srcSetDark="/images/content/emptyBoltDark.png"
                    alt="reffoDark"
                />
              </div>
              <div className={cn(styles.holderText)}>$1</div>
            </div>  
            <div className={cn(styles.holderLabel)}>Reffo's take</div>
          </div>
          
          <div className={cn(styles.holder, styles.item_two)}>
            <div >
              <div className={cn(styles.holderIcon)}>
                <Image className={styles.icon}
                    srcSet="/images/content/bankIcon.png"
                    srcSetDark="/images/content/bankIcon.png"
                    alt="reffoDark"
                />
              </div>
              <div className={cn(styles.holderText)}>{transferCost}</div>
            </div>  
            <div className={cn(styles.holderLabel)}>Transaction fee</div>
          </div>
          <div className={cn(styles.holder, styles.item_three)}>
            <div >
              <div className={cn(styles.holderIcon)}>
                <Image className={styles.icon}
                    srcSet="/images/content/forksIcon.png"
                    srcSetDark="/images/content/forksIcon.png"
                    alt="reffoDark"
                />
              </div>
              <div className={cn(styles.holderText)}>{payout}</div>
            </div>  
            <div className={cn(styles.holderLabel)}>Transferred to restaurant</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostCalculator;
