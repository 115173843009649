import React from "react";
// import TermsContent from "../../components/TermsContent";


const Class01Details = () => {
    return (
        <>
            {/* <TermsContent/> */}
            {/* <Interesting /> */}
        </>
    );
};

export default Class01Details;
