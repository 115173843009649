import React from "react";
// import Plan from "./Plan";
// import Signup from "./Signup";
// import Comment from "./Comment";
// import Class01Details from "../Class01Details";
// import Testimonials from "../../components/Testimonials";
import Waitlists from "../../components/Waitlists";

const Pricing = () => {
    return (
        <>
            <Waitlists />
            {/* <Class01Details /> */}
            {/* <Testimonials className="section-bg" /> */}
        </>
    );
};

export default Pricing;
