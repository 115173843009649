import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Book.module.sass";
import Icon from "../../../components/Icon";
import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Dine-in or take out",
    color: "#45B26B",
    images: "/images/content/clock.svg",
    alt: "user",
    content: "In the future, we plan to work with food delivery services!",
  },
  {
    title: "Good for the planet",
    color: "#9757D7",
    images: "/images/content/data.svg",
    alt: "data",
    content: "Dining during off-peak hours means less food waste, so you can feel go green when you dine out.",
  },
  {
    title: "Deals on your terms",
    color: "#3772FF",
    images: "/images/content/lightning.svg",
    alt: "lightning",
    content: "We know how to give you your space. If you're hungry, check Reffo! If not, we won't bug you.",
  },
];

const Book = () => {
  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/reffoRepeat.png"
              srcSetDark="/images/reffoRepeat.png"
              src="/images/reffoRepeat.png"
              srcDark="/images/reffoRepeat.png"
              alt="Reffo is offer backwards"
            />
          </div>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={300}
          >
            <img
              srcSet="/images/content/stickers/layer_6.png 2x"
              src="/images/content/stickers/layer_6.png"
              alt="Ball"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={300}
          >
            <img
              srcSet="/images/content/stickers/layer_4.png 2x"
              src="/images/content/stickers/layer_4.png"
              alt="Ball"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={300}
          >
            <img
              srcSet="/images/content/stickers/layer_5.png 2x"
              src="/images/content/stickers/layer_5.png"
              alt="Dumbbells"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>Why try Reffo?</h2>
          <div className={styles.info}>
            There's no risk, and no cost! Its easy to use because there's no app to download. 

          </div>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <img src={x.images} alt={x.alt} />
                </div>
                <div className={styles.details}>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.note}>
            We are launching in {" "}
            <strong className={styles.black}>Raleigh NC</strong>, but hope to expand to other areas soon!
          </div>
          <div className={styles.btns}>
            <Link className={cn("button", styles.button)} to="https://app.reffo.deals/">
              <span>Check out restaurant offers</span>
              <Icon name="arrow-right" size="10" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book;
