import { Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Contact from "./screens/Contact";
import Home from "./screens/Home";
import FAQS from "./screens/FAQS";
import AboutUs from "./screens/AboutUs";
import Philosophy from "./screens/Philosophy";
import Pricing from "./screens/Pricing";
import Restaurants from "./screens/Restaurants";
import Join from "./screens/Join";
import Class01 from "./screens/Class01";
import Class01Details from "./screens/Class01Details";
import Class02 from "./screens/Class02";
import Class02Details from "./screens/Class02Details";
import Lifestyle from "./screens/Lifestyle";
import Article from "./screens/Article";
import FoodWaste from "./screens/FoodWaste";
import RestaurantStartApplication from "./screens/RestaurantStartApplication";
import TermsOfService from "./screens/TermsOfService";



function App() {
    return (
        <Routes>
            <Route path="/">
                <Route
                    index
                    element={
                        <Page>
                            <Home />
                        </Page>
                    }
                />
                <Route
                    path="philosophy"
                    element={
                        <Page>
                            <Philosophy />
                        </Page>
                    }
                />
                <Route
                    path="restaurants"
                    element={
                        <Page>
                            <Restaurants />
                        </Page>
                    }
                />
                <Route
                    path="about"
                    element={
                        <Page>
                            <AboutUs />
                        </Page>
                    }
                />
                <Route
                    path="faqs"
                    element={
                        <Page>
                            <FAQS />
                        </Page>
                    }
                />
                <Route
                    path="food-waste"
                    element={
                        <Page>
                            <FoodWaste />
                        </Page>
                    }
                />
                <Route
                    path="contact"
                    element={
                        <Page>
                            <Contact />
                        </Page>
                    }
                />
                <Route
                    path="pricing"
                    element={
                        <Page>
                            <Pricing />
                        </Page>
                    }
                />
                <Route
                    path="terms-of-service"
                    element={
                        <Page>
                            <TermsOfService />
                        </Page>
                    }
                />
                <Route
                    path="start-restaurant-application"
                    element={
                        <Page>
                            <RestaurantStartApplication />
                        </Page>
                    }
                />
                <Route
                    path="pricing"
                    element={
                        <Page>
                            <Pricing />
                        </Page>
                    }
                />
                <Route
                    path="join"
                    element={
                        <Page>
                            <Join />
                        </Page>
                    }
                />
                <Route
                    path="download"
                    element={
                        <Page>
                            <Join />
                        </Page>
                    }
                />
                <Route
                    path="downloads"
                    element={
                        <Page>
                            <Join />
                        </Page>
                    }
                />
                <Route
                    path="join"
                    element={
                        <Page>
                            <Join />
                        </Page>
                    }
                />
                <Route
                    path="class01"
                    element={
                        <Page>
                            <Class01 />
                        </Page>
                    }
                />
                <Route
                    path="class01-details"
                    element={
                        <Page>
                            <Class01Details />
                        </Page>
                    }
                />
                <Route
                    path="class02"
                    element={
                        <Page>
                            <Class02 />
                        </Page>
                    }
                />
                <Route
                    path="class02-details"
                    element={
                        <Page>
                            <Class02Details />
                        </Page>
                    }
                />
                <Route
                    path="lifestyle"
                    element={
                        <Page>
                            <Lifestyle />
                        </Page>
                    }
                />
                <Route
                    path="article"
                    element={
                        <Page>
                            <Article />
                        </Page>
                    }
                />
            </Route>
        </Routes>
    );
}

export default App;
