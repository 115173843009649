const tips = [];
const courses = [];

const socials = [
  {
    type: "twitter",
    url: "https://twitter.com/dougkinnison"
  },
  {
    type: "twitter",
    url: "https://twitter.com/reffodeals",
  },
  {
    type: "linkedin",
    url: "https://www.linkedin.com/in/douglaskinnison/",
  },
];

export const wasteReductionList = [
  {
    title: "Food waste cool stuff",
    courses: [
      {
        name: "EcoFoodieTips",
        url: "https://twitter.com/ecofoodietips",
        image: "/images/content/ecofoodietips.png",
        position: "Avoid food waste",
        about: "There's an often overlooked path to financial well-being and eco-consciousness that's hiding right in our kitchens: reducing food waste!",
        courses: courses,      },
      {
        name: "Misfits Market",
        url: "https://www.misfitsmarket.com/?promo=COOKWME-NV6WJPULKDT",
        image: "/images/content/misfits.png",
        position: "Fixing the 'ugly' problem",
        about: "Misfits Market is dedicated to making affordable, high-quality food more accessible while helping break the cycle of food waste. We work directly with farmers and makers to rescue organic produce and other grocery items that might otherwise go to waste, then deliver them to your door—all while passing the savings on to you. Every Misfits Market box you order benefits farmers, helps prevent food waste, and ultimately helps save our environment.",
        courses: courses,
      },
      {
        name: "Compost Now",
        url: "https://www.compostnow.org/",
        image: "/images/content/compostNow.png",
        position: "Breaking down the basics",
        about: "The easiest way for homes and businesses to turn food scraps into nutrient rich compost.",
        courses: courses,
      },
      {
        name: "Rescue Leftover Cuisine",
        url: "https://www.rescuingleftovercuisine.org/",
        image: "/images/content/rescueLeftoverCuisine.png",
        position: "Food rescue with RLC",
        about: "Rescuing Leftover Cuisine is a national 501(c)3 nonprofit food rescue organization, operating across the United States and headquartered in New York City, that redistributes excess food to people experiencing food insecurity.",
        courses: courses,
      }, 
    ],
    title: "Food waste tips",
    tips: [
      {
        name: "The food tips",
        url: "https://twitter.com/ecofoodietips",
        image: "/images/content/ecofoodietips.png",
        position: "Avoid food waste",
        about: "There's an often overlooked path to financial well-being and eco-consciousness that's hiding right in our kitchens: reducing food waste!",
        tips: tips,      },
      {
        name: "Misfits Market",
        url: "https://www.misfitsmarket.com/?promo=COOKWME-NV6WJPULKDT",
        image: "/images/content/misfits.png",
        position: "Fixing the 'ugly' problem",
        about: "Misfits Market is dedicated to making affordable, high-quality food more accessible while helping break the cycle of food waste. We work directly with farmers and makers to rescue organic produce and other grocery items that might otherwise go to waste, then deliver them to your door—all while passing the savings on to you. Every Misfits Market box you order benefits farmers, helps prevent food waste, and ultimately helps save our environment.",
        tips: tips,
      },
      {
        name: "Compost Now",
        url: "https://www.compostnow.org/",
        image: "/images/content/compostNow.png",
        position: "Breaking down the basics",
        about: "The easiest way for homes and businesses to turn food scraps into nutrient rich compost.",
        tips: tips,
      },
      {
        name: "Rescue Leftover Cuisine",
        url: "https://www.rescuingleftovercuisine.org/",
        image: "/images/content/rescueLeftoverCuisine.png",
        position: "Food rescue with RLC",
        about: "Rescuing Leftover Cuisine is a national 501(c)3 nonprofit food rescue organization, operating across the United States and headquartered in New York City, that redistributes excess food to people experiencing food insecurity.",
        tips: tips,
      }, 
    ],
  },
];
