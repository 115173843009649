import React from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import Subscription from "../../../components/WaitList";
import Image from "../../../components/Image";

const Hero = () => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <div className={styles.col}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/joinWaitlist.png 2x"
              srcSetDark="/images/content/joinWaitlist.png 2x"
              src="/images/content/joinWaitlist.png"
              srcDark="/images/content/joinWaitlist.png"
              alt="Download bg"
            />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.wrap}>
            <div className={cn("stage", styles.stage)}>
              Become an early adopter
            </div>
            <h1 className={cn("h1", styles.title)}>
              Join the waitlist!
            </h1>
            <div className={styles.text}>
              We're launching in Raleigh, NC in June 2024. Sign up to receive an email when restaurant offers are live in Reffo!
            </div>
            <Subscription
              className={styles.subscription}
              placeholderEmail="Enter your email"
              placeholderZip="Enter your zip code"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
