import React from "react";
// import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
// import Slider from "react-slick";
import styles from "./Hero.module.sass";
// import Icon from "../../../components/Icon";



const status = [
    {
        status: "blue",
        statusContent: "Foodies",
    },
    {
        status: "red",
        statusContent: "Restaurant",
    },
    {
        status: "green",
        statusContent: "FAQ",
    },
];

const photos = [
    {
        image: "/images/content/workout-pic.png",
        image2x: "/images/content/workout-pic@2x.png",
        play: true,
    },
    {
        image: "/images/content/workout-pic.png",
        image2x: "/images/content/workout-pic@2x.png",
    },
    {
        image: "/images/content/workout-pic.png",
        image2x: "/images/content/workout-pic@2x.png",
        play: true,
    },
    {
        image: "/images/content/workout-pic.png",
        image2x: "/images/content/workout-pic@2x.png",
    },
];

const items = [
    {
        title: "Classes",
        content: "7",
        icon: "calendar",
        size: "16",
    },
    {
        title: "Average duration",
        content: "20 minutes",
        icon: "clock",
        size: "16",
    },
    {
        title: "Difficulty",
        content: "Beginner",
        color: "#45B26B",
        icon: "scoreboard",
        size: "20",
    },
    {
        title: "Intensity",
        content: "Level 2",
        icon: "candlesticks",
        size: "18",
    },
];

const list = [
    "Get an account setup for free",
    "Create unlimted deals, and only pay when a deal closes",
    "Reffo will never invoice your business for deals",
];

const Hero = ({value, setValue}) => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
    };

    return (
        <div className={cn("section", styles.section)}>
            
            <div className={cn("container", styles.container)} >
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.details}>
                            {status.map((x, index) => (
                                <div
                                    className={cn(
                                        { "status-red": x.status === "red" },
                                        { "status-green": x.status === "green"},
                                        { "status-blue": x.status === "blue"},
                                        styles.status
                                    )}
                                    key={index}
                                >
                                    {x.statusContent}
                                </div>
                            ))}
                        </div>
                        <h2 className={cn("h2", styles.title)}>
                            Frequently asked questions
                        </h2>
                        <br/>
                        <details open>
                            <summary className="faq-title"><b>What are the different types of deals?</b></summary>
                            <br/><h4>Reffo currently features 3 different offer types:</h4>
                            <ul>
                                <li><h3>Deals ( temporary discounts)</h3></li>
                                        <ul>
                                            <li>Deals are temporary discounts that you can secure with a deposit. These deals can help to prevent food waste! Check back often to see what's being offered. In the near future, you can favorite certain restaurants and opt to receive notifications if they publish a new deal on Reffo. Deposits for these types of offers generally range from $5-$20.</li><br/>
                                        </ul>
                                <li><h3>Experiences</h3></li>
                                        <ul>
                                            <li>These exclusive offers are only available through Reffo, such as off-menu dishes, wine pairings, community events, dessert tastings, opportunities to meet the chef, and more! Since these are unique and limited in nature, often the restaurant will set the transaction amount as the full cost of the experience paid in full through Reffo, and when you arrive you will be taken care of.</li><br/>
                                        </ul>
                                <li><h3>Birthday specials</h3></li>
                                        <ul>
                                            <li>These are treats offered on the day, week, or month of your birthday. No deposit required! 😀</li><br/>
                                        </ul>
                                <li><i><h4>Many more coming soon!</h4></i></li><br/>
                            </ul>
                            <p>* <i>Restaurants are encouraged to customize the offer, set the transaction amount, and get creative! Please check the details of the offer.</i></p><br/>
                        </details>
                        <details open>
                            <summary className="faq-title"><b>I just bought a Reffo, can I cancel it?</b></summary>
                            <p>You can cancel if the deal redemption window has not yet started.</p>
                        </details>
                        <br/>
                        <details >
                            <summary className="faq-title"><b>What happens when I make a deposit on Reffo?</b></summary>
                            <p>Your deposit will be credited towards the total check when you purchase your meal at the restaurant. Simply show the waitstaff your Reffo voucher on your phone. </p>
                        </details>
                        <br/>
                        <details >
                            <summary className="faq-title"><b>Can I get a refund for my deposit?</b></summary>
                            <p>In most cases the deposit is non-refundable. Please contact us if you had a technical problem with the app.</p>
                        </details>
                        <br/>
                        <details >
                            <summary className="faq-title"><b>What happens if I am late or unable to make my reservation before the deal ends?</b></summary>
                            <p>Your deposit is non-refundable. It’s up to the discretion of the restaurant if they would be willing to honor the deal after it ends. </p>
                        </details>
                        <br/>
                        <details >
                            <summary className="faq-title"><b>When will Reffo be available in my area?</b></summary>
                            <p>Head over to the sign up page and enter your email and zip code, and we will alert you when Reffo is at restaurants near you. Every entry counts as a vote towards Reffo contacting restaurants in your city!</p>
                        </details>
                        <br/>
                        <details >
                            <summary className="faq-title"><b>How does Reffo help the environment?</b></summary>
                            <p>Reffo helps restaurants to avoid food waste by bringing in hungry customers when food might have otherwise gone to waste.</p>
                        </details>
                        <br/>
                        <details >
                            <summary className="faq-title"><b>Are you hiring?</b></summary>
                            <p>Currently we are seeking investment funding so we can grow our team! Check back in the future, or feel free to contact us if you want to connect.</p>
                        </details>
                        <br/>
                        <details >
                            <summary className="faq-title"><b>How do deposits for deals work?</b></summary>
                            <p>Restaurants determine the amount for deposits. These deposits are sent to the restaurant minus the $1 Reffo fee. All transactions run through Stripe.</p>
                        </details>
                        <br/>
                        <details >
                            <summary className="faq-title"><b>Who determines the deals offered?</b></summary>
                            <p>We believe that restaurants know their business best! They are responsible for creating the deals, and determining when and where deals are available.</p>
                        </details>
                        <br/>
                        <details >
                            <summary className="faq-title"><b>What features can deals have?</b></summary>
                            <p>Reffo allows restaurants to manage the following fields:</p>
                            <ul>
                                <li>- Deal details</li>
                                <li>- Deal rules</li>
                                <li>- Party size</li>
                                <li>- Time range</li>
                                <li>- Quantity</li>
                                <li>- Active / Inactive</li>
                                <li>- Deposit size</li>
                            </ul>
                        </details>
                        <br/>
                        <details >
                            <summary className="faq-title"><b>When will Reffo be available in my area?</b></summary>
                            <p>Head over to the sign up page and enter your email and zip code, get alerted when Reffo is near you! Every entry counts as a vote towards Reffo contacting restaurants in your city!</p>
                        </details>
                        <br />
                        <details >
                            <summary className="faq-title"><b>I manage a restaurant, can you help me figure which deals to create?</b></summary>
                            <p>Yes! we are happy to chat with you and discuss how to set up deals at your restaurant. </p>
                        </details>
                        <br />
                        <details >
                            <summary className="faq-title"><b>I have more questions, ideas, or feedback!</b></summary>
                            <p>Awesome! You can reach us via our <a href="/contact">contact form</a> if you'd like to connect.</p>
                        </details>
                    </div>
                    <div className={styles.col}>   
                        {/* <div><h1>Restuarants Owners</h1></div>                     
                        <div className={styles.list}>
                            {list.map((x, index) => (
                                <div className={styles.box} key={index}>
                                    {x}
                                </div>
                            ))}
                        </div>
                        <div className={styles.btns}>
                            <button className={cn("button", styles.button)}>
                                Start the application process
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Hero;
