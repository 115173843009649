import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import WaitList from "../WaitList";
import Icon from "../Icon";
import Image from "../Image";
import Theme from "../Theme";

const menu = [
    {
        title: "Restaurants",
        url: "/restaurants",
    },
    {
        title: "Pricing",
        url: "/pricing",
    },
    {
        title: "FAQs",
        url: "/faqs",
    },
    {
        title: "About",
        url: "/about",
    },
    {
        title: "Contact",
        url: "/contact",
    },
    
];

const socials = [
    {
        title: "facebook",
        size: "16",
        url: "https://www.facebook.com/reffodeals/",
    },
    {
        title: "twitter",
        size: "18",
        url: "https://twitter.com/reffodeals",
    },
    {
        title: "instagram",
        size: "16",
        url: "https://www.instagram.com/reffodeals/",
    },
];

const Footer = () => {
    const [visible, setVisible] = useState(false);

    const { pathname } = useLocation();

    return (
        <footer className={styles.footer}>
            <div className={styles.body}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.col}>
                        <div className={styles.box}>
                            <Link className={styles.logo} to="/">
                                <Image
                                    className={styles.pic}
                                    src="/images/reffoIcon_small.svg"
                                    srcDark="/images/reffoIcon_small.svg"
                                    alt="Reffo rocks!"
                                />
                            </Link>
                            <Theme className={styles.theme} />
                        </div>
                        <div
                            className={cn(styles.item, {
                                [styles.active]: visible,
                            })}
                        >
                            <div
                                className={styles.category}
                                onClick={() => setVisible(!visible)}
                            >
                                footer nav
                                <Icon name="arrow-bottom" size="9" />
                            </div>
                            <div className={styles.menu}>
                                {menu.map((x, index) => (
                                    <NavLink
                                        className={cn(styles.link, {
                                            [styles.active]: pathname === x.url,
                                        })}
                                        to={x.url}
                                        key={index}
                                    >
                                        {x.title}
                                    </NavLink>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.category}>contact</div>
                        <div className={styles.info}>
                            <p><a href="mailto:info@reffo.deals">info@reffo.deals</a></p>
                            <p>Raleigh, NC</p>
                            <p>United States</p>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.category}>Join the waitlist</div>
                        <div className={styles.info}>
                            Join our waitlist and we'll let you know as soon as we're in your area!
                        </div>
                        <WaitList
                            className={styles.subscription}
                            placeholderEmail="Enter your email"
                            placeholderZip="Enter your zipcode"
                        />
                    </div>
                </div>
            </div>
            <div className={styles.foot}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.copyright}>
                        Copyright © 2024 Reffo Inc. All rights reserved
                    </div>
                    <div className={styles.socials}>
                        {socials.map((x, index) => (
                            <a
                                className={styles.social}
                                href={x.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={index}
                            >
                                <Icon name={x.title} size={x.size} />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
