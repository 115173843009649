import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styles from "./Lifestyle.module.sass";
import Icon from "../../../components/Icon";
import ScrollParallax from "../../../components/ScrollParallax";

import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'



const items = [
  {
    title: "Equality of opportunity, never equality of outcome",
    vidID: '5z47OhzskqU',
    vidTitle: "Equality of opportunity, never equality of outcome",
    image: "/images/content/lifestyle-pic-1.png",
    image2x: "/images/content/lifestyle-pic-1@2x.png",
    content:
      "To guarantee equal outcomes is to guarantee oppression and discrimination, segregation and resentment. This will lead to division and a divided people cannot stand.",
  },
  {
    title: "The freedom of speech",
    vidID: '5z47OhzskqU',
    vidTitle: "Freedom of speech",
    image: "/images/content/lifestyle-pic-2.png",
    image2x: "/images/content/lifestyle-pic-2@2x.png",
    content:
    "The freedom of speech is the bedrock of freedom worldwide. It is vital for a free society to be able to speak truth to power. As Orwell said in 1984, 'Freedom is the ability to say that 2+2=4. '",
  },
  {
    title: "The freedom to hear",
    vidID: '5z47OhzskqU',
    vidTitle: "Freedom to hear",
    image: "/images/content/lifestyle-pic-3.png",
    image2x: "/images/content/lifestyle-pic-3@2x.png",
    content:
      "People must be free to read, listen, or subscribe to whatever and whoever they choose. The freedom to hear is the lifeblood of the freedom of speech.",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Lifestyle = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("stage-small", styles.stage)}>The core of our philosphy</div>
            <h2 className={cn("h2", styles.title)}>There are two sides <br></br>to every coin</h2>
            <div className={styles.info}>
              The flip side to the freedom of speech is not censorship, it's the freedom to hear.{" "}
            </div>
            <Link
              className={cn("button-stroke", styles.button)}
              to="/class02-details"
            >
              <span>Join our community</span>
              <Icon name="arrow-right" size="10" />
            </Link>
          </div>
          <div className={styles.col}>
            <div className={styles.coin}>
              <img
                srcSet="/images/coin.png 2x"
                src="/images/coin.png"
                alt="coin"
              />
            </div>
          </div>
        </div>
        <div className={styles.wrap}>
          <Slider
            className={cn("lifestyle-slider", styles.slider)}
            {...settings}
          >
            {items.map((x, index) => (
              <ScrollParallax className={styles.item} key={index}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.details}>
                      <div className={styles.number}>0{index + 1}.</div>
                      <div className={styles.category}>{x.title}</div>
                      <div className={styles.content}>{x.content}</div>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div>
                      <LiteYouTubeEmbed 
                          id={x.vidID}
                          title={x.vidTitle}
                      />
                    </div>
                    
                    {/* <img
                      srcSet={`${x.image2x} 2x`}
                      src={x.image}
                      alt="Lifestyle"
                    /> */}
                  </div>
                </div>
              </ScrollParallax>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Lifestyle;
