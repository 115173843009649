const courses = [
  {
    title: "Fight Algorithmic Discrimination",
    url: "/class01-details",
    image: "/images/content/card-pic-1.png",
    image2x: "/images/content/card-pic-1@2x.png",
    category: "white",
    categoryText: "philosophy",
    level: "green",
    levelText: "philosophy",
    counterVideo: "2k",
    // counterUser: "160",
  },
  {
    title: "Empowered Creators Build Communities",
    url: "/class01-details",
    image: "/images/content/card-pic-2.png",
    image2x: "/images/content/card-pic-2@2x.png",
    category: "white",
    categoryText: "yoga",
    level: "green",
    levelText: "beginner",
    counterVideo: "7",
    counterUser: "160",
  },
  {
    title: "Freedom From Centralized Platforms",
    url: "/class01-details",
    image: "/images/content/card-pic-3.png",
    image2x: "/images/content/card-pic-3@2x.png",
    category: "white",
    categoryText: "yoga",
    level: "green",
    levelText: "beginner",
    counterVideo: "7",
    counterUser: "160",
  },
  {
    title: "The Freedom to Hear",
    url: "/class01-details",
    image: "/images/content/card-pic-4.png",
    image2x: "/images/content/card-pic-4@2x.png",
    category: "white",
    categoryText: "yoga",
    level: "green",
    levelText: "beginner",
    counterVideo: "7",
    counterUser: "160",
  },
];

const socials = [
  {
    type: "twitter",
    url: "https://twitter.com/dougkinnison"
  },
  {
    type: "twitter",
    url: "https://twitter.com/reffodeals",
  },
  {
    type: "linkedin",
    url: "https://www.linkedin.com/in/douglaskinnison/",
  },
];

export const trainersList = [
  {
    title: "Team",
    courses: [
      {
        name: "Douglas Kinnison",
        image: "/images/content/avatar-1.png",
        position: "Founder",
        about: "I'm a serial entrepreneur that loves to innovate and build up others.\nI'm a co-founder and CEO of Reffo, and the CEO of Division 6, a software development house. I advise businesses on marketing, technology, and business development.\nI always talk about entropy.",
        courses: courses,
        socials: socials,
      },
      {
        name: "Kendall Totten",
        image: "/images/content/avatar-2.png",
        position: "Advisory Board Member",
        about: "From design to development, I’ve contributed to websites such as dallascowboys.com, weather.com and redhat.com. I helped to build & champion content management & design systems. I’m now an Engineering Manager at a Web 3.0 startup, and specialize in people and program management.",
        courses: courses,
        socials: socials,
      },
      {
        name: "Sarah Schmitt",
        image: "/images/content/avatar-6.png",
        position: "Advisory Board Member",
        about: "As a Senior Manager of Strategic Partnerships at Toast, I lead and define the strategy for large, revenue-generating alliances. With over nine years of global business development and partnership management experience, I have a proven track record of negotiating complex contracts, demonstrating sales effectiveness, and building key executive relationships.\nI am also an MBA candidate at the Kellogg School of Management, where I am enhancing my skills and expertise in strategy, leadership, and innovation. I have a passion for creating value and impact through strategic partnerships that align with the product objectives and mission of Toast and its customers. I am always eager to learn, collaborate, and deliver results in a fast-paced and dynamic environment.",
        courses: courses,
        socials: socials,
      },
      {
        name: "Garth Henson",
        image: "/images/content/avatar-7.png",
        position: "Advisory Board Member",
        about: "Currently, Garth is rocking the role of Senior Director of Engineering at CareAcademy, bringing a wealth of experience from tech giants like Amazon, Microsoft, Disney, and Lucasfilm. \nBeyond his impressive resume, Garth is also the go-to technical guru for startups, providing guidance and mentorship to emerging innovators. A techie at heart, he is all about driving progress with a dash of passion and a sprinkle of brilliance.",
        courses: courses,
        socials: socials,
      },
      {
        name: "David Lin",
        image: "/images/content/avatar-8.png",
        position: "Advisory Board Member",
        about: "David is a serial entrepreneur and senior executive with extensive management, finance and leadership experience across varied industries with over 20 years experience in operations.  He was founder of a venture backed mobile marketing technology company focused on restaurants, and more recently as the CEO of a middle market regional coffee chain.  He also spent over 10 years in Venture capital and Private Equity.",
        courses: courses,
        socials: socials,
      },
      {
        name: "Chris Leithe",
        image: "/images/content/avatar-9.png",
        position: "Advisory Board Member",
        about: "Chris builds and scales revenue growth for tech and services businesses. 'I spent 4 years helping scale Citrix Sharefile's business development, partnerships, and sales teams (from 30 to 300 people), Adwerx's revenue from $10MM to $27MM, and built out enterprise and direct to consumer sales motions for Foodsmart. I am a business growth consultant, entrepreneur, and am the co-founder of Audience Acceleration Labs which helps companies and individuals build and monetize global audiences. When I'm not playing start-up, I'm playing music or biking around town.'",
        courses: courses,
        socials: socials,
      },
    ],
  },
  // {
  //   title: "Gym & Fitness",
  //   courses: [
  //     {
  //       name: "Marcelino Hettinger",
  //       image: "/images/content/avatar-3.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Rosemarie Hintz",
  //       image: "/images/content/avatar-4.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Syble Prohaska",
  //       image: "/images/content/avatar-5.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Ila Reinger",
  //       image: "/images/content/avatar-6.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Baron Toy",
  //       image: "/images/content/avatar-1.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Kacie Kiehn",
  //       image: "/images/content/avatar-2.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Baron Toy",
  //       image: "/images/content/avatar-1.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Kacie Kiehn",
  //       image: "/images/content/avatar-2.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //   ],
  // },
  // {
  //   title: "Gymnastics",
  //   courses: [
  //     {
  //       name: "Baron Toy",
  //       image: "/images/content/avatar-1.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Kacie Kiehn",
  //       image: "/images/content/avatar-2.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Marcelino Hettinger",
  //       image: "/images/content/avatar-3.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Rosemarie Hintz",
  //       image: "/images/content/avatar-4.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Syble Prohaska",
  //       image: "/images/content/avatar-5.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Ila Reinger",
  //       image: "/images/content/avatar-6.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Baron Toy",
  //       image: "/images/content/avatar-1.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //   ],
  // },
  // {
  //   title: "Mindfulness",
  //   courses: [
  //     {
  //       name: "Baron Toy",
  //       image: "/images/content/avatar-1.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Kacie Kiehn",
  //       image: "/images/content/avatar-2.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Marcelino Hettinger",
  //       image: "/images/content/avatar-3.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Rosemarie Hintz",
  //       image: "/images/content/avatar-4.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Syble Prohaska",
  //       image: "/images/content/avatar-5.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Ila Reinger",
  //       image: "/images/content/avatar-6.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Baron Toy",
  //       image: "/images/content/avatar-1.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //     {
  //       name: "Kacie Kiehn",
  //       image: "/images/content/avatar-2.png",
  //       position: "Yoga Master",
  //       courses: courses,
  //       socials: socials,
  //     },
  //   ],
  // },
];
