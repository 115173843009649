import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import ScrollButton from "../../../components/ScrollButton";
import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            Passionate foodies.
          </div>
          <h1 className={cn("h1", styles.title)}>
            On a mission to heal the food industry.
          </h1>
          <div className={styles.text}>
            We love to serve. Our mission is to help restaurants thrive and to bring deals, birthday specials, and experiences to fellow foodies!
          </div>
          {/* <div className={styles.btns}>
            <Link className={cn("button", styles.button)} to="https://app.reffo.deals">
              Check out our deals
            </Link>
          </div> */}
        </div>
        <ScrollButton
          onScroll={() =>
            scrollToRef.current.scrollIntoView({ behavior: "smooth" })
          }
          className={styles.scroll}
        />
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/dk.png 2x"
              srcSetDark="/images/dk.png 2x"
              src="/images/dk.png"
              srcDark="/images/dk.png"
              alt="Main"
            />
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Hero;
