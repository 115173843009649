import React from "react";
import cn from "classnames";
import styles from "./About.module.sass";
// import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";


const items = [
  {
    title: "No discounts? No problem.",
    content:
    "Exclusive experiences might mean a fancy wine-pairing event, a chance to meet the chef, or an off-menu special pre-fixe menu only available for a limited time. Set the deposit amount for the full cost of the experience, and Reffo only keeps $5.",
    color: "#9757D7",
  },
  {
    title: "Set the offer window as you see fit.",
    content:
      "You may wish to make an offer available several weeks before the event. We have an entirely separate offer type to make this easy to do.",
    color: "#45B26B",
  },
];

const About = () => {
  return (
    <div className={cn("section", styles.book)}>
      <div className={cn("container", styles.container)}>
 
        <div className={styles.row}>
          <div className={styles.col}>
                      <div className={cn("stage", styles.stage)}>Choose an offer type</div>
           
            <h2 className={cn("h2", styles.title)}>
              What if discounts aren't your thing?
            </h2>
            <div className={styles.info}>
            If your establishment isn't interested in offering flash deals, you can create exclusive experiences instead. 
              <br/><br/><br/>
            </div>
            <div className={styles.list}>
              {items.map((x, index) => (
                <ScrollParallax className={styles.item} key={index}>
                  <div
                    className={styles.number}
                    style={{ backgroundColor: x.color }}
                  >
                    0{index + 1}
                  </div>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </ScrollParallax>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.bg}>
              <img
                srcSet="/images/content/womanChefCooking.png 2x"
                src="/images/content/womanChefCooking.png"
                alt="About pic"
              />
              {/* <div>
                <ScrollParallax className={styles.preview} animateIn="fadeInUp">
                  <Image
                    srcSet="/images/content/stickers/layer 23.png 2x"
                    srcSetDark="/images/content/stickers/layer 23.png 2x"
                    src="/images/content/stickers/layer 23.png"
                    srcDark="/images/content/stickers/layer 23.png"
                    alt="Rocket"
                  />
                </ScrollParallax>
                <ScrollParallax className={styles.preview} animateIn="fadeInUp">
                  <img
                    srcSet="/images/content/stickers/layer 25.png 2x"
                    src="/images/content/stickers/layer 25.png"
                    alt="Ball"
                  />
                </ScrollParallax>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
