import React, { useRef } from "react";
import Hero from "./Hero";
import Trainers from "./Trainers";
import Community from "../../components/Community";
import Programs from "../../components/Programs";

const FoodWaste = () => {
    const scrollToRef = useRef(null);

    return (
        <>
            <Hero scrollToRef={scrollToRef} />
            <Programs
                scrollToRef={scrollToRef}
                classNameTitle="h4"
                title="Reffo helps to connect you with perfectly prepared meals that may have otherwise gone to waste."
            />
            <Trainers />
            <Community />
        </>
    );
};

export default FoodWaste;
