import React from "react";
import Hero from "./Hero";
// import Platform from "./Platform";
// import Community from "../../components/Community";

const Join = () => {
    return (
        <>
            <Hero />
            {/* <Platform />
            <Community /> */}
        </>
    );
};

export default Join;
