import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/

export const RestaurantSignupSchema = Yup.object().shape({
    businessName: Yup.string()
      .min(2, 'Too Short!')
      .required('Required'),
    businessOwner: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
      ownerEmail: Yup.string().email('Invalid email')
      .required('Required'),
      ownerPhone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
      .required('Required'),
      businessAddress1: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
      businessAddress2: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
      businessCity: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
      businessState: Yup.string()
      .min(2, 'Too Short!')
      .max(2, 'Too Long!')
      .required('Required'),
      businessZipcode: Yup.string().matches(zipRegExp, "Zipcode is not valid")
      .min(5, 'Must be exactly 5 characters')
      .max(5, 'Must be exactly 5 characters')
      .required('Required'),
  });