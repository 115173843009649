import React, { useState } from "react";
import cn from "classnames";
import styles from "./RestaurantInitForm.module.sass";
import Icon from "../Icon";
import { collection, addDoc, serverTimestamp, } from "firebase/firestore";
import { db } from '../../firebase';

import { Formik, useFormik, Field, useField } from 'formik';
// import * as Yup from 'yup';
import { RestaurantSignupSchema } from "./restaurantSignupInitValidation";
import ModalStartedApplication from "../../components/ModalStartedApplication";
// Firebase auth
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";



const RestaurantInitFormikForm = ({ placeholderBusinessName,  
                                      placeholderBusinessOwner, 
                                      placeholderOwnerPhone, 
                                      placeholderOwnerEmail, 
                                      placeholderAddress1, 
                                      placeholderAddress2, 
                                      placeholderCity, 
                                      placeholderState, 
                                      placeholderZipcode, }) => {

    //Firebase auth
    const auth = getAuth();

    const [visibleModal, setVisibleModal] = useState(false);   

    const onSubmit = async (values, actions, e) => {
      const result = Math.random().toString(36).substring(2,8);
      console.log('submitted');
      try {
        const docRef = await addDoc(collection(db, 'admin/applications/restaurants'), {
          businessLegalName: values.businessName,
          businessOwner: values.businessOwner,
          ownerEmail: values.ownerEmail,
          ownerPhone: values.ownerPhone,
          businessAddress1: values.businessAddress1,
          businessAddress2: values.businessAddress2,
          businessCity: values.businessCity,
          businessState: values.businessState,
          businessZip: values.businessZipcode,
          timestamp: serverTimestamp(),
          terms: values.acceptTerms,
        });
        createUserWithEmailAndPassword(auth, values.ownerEmail, result) 
        .then((userCredential) => {
          // Signed up 
          const user = userCredential.user;
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });

        actions.resetForm();
        setVisibleModal(true);
        //Stripe Integration
        
    
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    };

    const {values, errors, touched, isSubmitting, handleBlur, handleSubmit, handleChange} = useFormik({
      initialValues: {
        businessName: '',
        businessOwner: '',
        ownerEmail: '',
        ownerPhone: '',
        businessAddress1: '',
        businessAddress2: '',
        businessCity: '',
        businessState: '',
        businessZipcode: '',
        terms: false,
      },
      validationSchema: RestaurantSignupSchema,
      onSubmit,
    })
  
  return (
    <div>
      <Formik
      >
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit(e)}}>
          {errors.businessName && touched.businessName && <small>{errors.businessName}</small>}

          <input 
            // className={errors.businessName ? styles.inputError : styles.input}
            className={styles.input}
            value={values.businessName}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            id="businessName"
            placeholder={placeholderBusinessName}
          />
          {errors.businessOwner && touched.businessOwner && <small>{errors.businessOwner}</small>}

          <input 
            // className={errors.businessOwner ? styles.inputError : styles.input}
            className={styles.input}
            value={values.businessOwner}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            id="businessOwner"
            placeholder={placeholderBusinessOwner}
          />

          {errors.ownerEmail && touched.ownerEmail && <small>{errors.ownerEmail}</small>}

          <input 
            // className={errors.ownerEmail ? styles.inputError : styles.input}
            className={styles.input}
            value={values.ownerEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            id="ownerEmail"
            placeholder={placeholderOwnerEmail}
          />

          {errors.ownerPhone && touched.ownerPhone && <small>{errors.ownerPhone}</small>}

          <input 
            // className={errors.ownerPhone ? styles.inputError : styles.input}
            className={styles.input}
            value={values.ownerPhone}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            id="ownerPhone"
            placeholder={placeholderOwnerPhone}
          />

          {errors.businessAddress1 && touched.businessAddress1 && <small>{errors.businessAddress1}</small>}

          <input 
            // className={errors.businessAddress1 ? styles.inputError : styles.input}
            className={styles.input}
            value={values.businessAddress1}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            id="businessAddress1"
            placeholder={placeholderAddress1}
          />

          <input 
            className={styles.input}
            value={values.businessAddress2}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            id="businessAddress2"
            placeholder={placeholderAddress2}
          />


          <span className={cn("stackForm", styles.stackForm)}><span>
          {errors.businessCity && touched.businessCity && <small>{errors.businessCity}</small>}

          <input 
            // className={errors.businessCity ? styles.inputError : styles.input}
            className={styles.input}
            value={values.businessCity}
            onChange={handleChange}
            // onBlur={handleBlur}
            type="text"
            id="businessCity"
            name="businessCity"
            placeholder={placeholderCity}
          />
          </span></span>

          <span className={cn("stackForm", styles.stackForm)}><span>
          {errors.businessState && touched.businessState && <small>{errors.businessState}</small>}
          <input 
            // className={errors.businessState ? styles.inputError : styles.input}
            className={styles.input}
            value={values.businessState}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            id="businessState"
            name="businessState"
            placeholder={placeholderState}
          />
          </span></span>

          <span className={cn("stackForm", styles.stackForm)}><span>
          {errors.businessZipcode && touched.businessZipcode && <small>{errors.businessZipcode}</small>}
          <input 
            className={styles.input}
            value={values.businessZipcode}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            id="businessZipcode"
            name="businessZipcode"
            placeholder={placeholderZipcode}
          />
          </span></span>

          <input 
            className={styles.inputCheck}
            value={values.acceptTerms}
            onChange={handleChange}
            onBlur={handleBlur}
            type="checkbox"
            id="acceptTerms"
            name="acceptTerms"
          />
          <span className={styles.agreement}>By submitting this application, you are agreeing to the <a href="https://app.termly.io/document/terms-of-service/bb3ef5f7-9a8d-46bb-a563-e4312cd0a9f2" target="_blank">Terms of Service</a>.</span>
          


          <div className={styles.btns}>
            <button disabled={isSubmitting || !values.acceptTerms} className={cn("button", styles.button)} type="submit">
              <span>Submit</span>
              <Icon name="arrow-right" size="10" />
            </button>
          </div>
            
        </form>
      </Formik>
      <ModalStartedApplication
          visible={visibleModal}
          
          onClose={() => setVisibleModal(false)}
        />
      </div>
  )};

export default RestaurantInitFormikForm;
