import React from "react";
import cn from "classnames";
import styles from "./Community.module.sass";
import Image from "../Image";
import { Link } from "react-router-dom";
import WaitList from "../WaitList";

const Community = () => {
  return (
    <div className={cn("section-pb zero-bottom", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div >
          <Image
            srcSet="/images/content/bowlBranding.png 2x"
            srcSetDark="/images/content/bowlBranding.png 2x"
            src="/images/content/bowlBranding.png"
            srcDark="/images/content/bowlBranding.png"
            alt="Dumbbell"
          />
        </div>
        {/* <div className={cn("stage-small", styles.stage)}>
        Sign Up for Free!
        </div> */}
        <br/><br/>
        <h2 className={cn("h2", styles.title)}>Interested?</h2>
        <div className={styles.text}>
          When you create an account with Reffo, you can be the first to snag available offers!
        <br/><br/>
            <div className={styles.btns}>
                <Link
                    className={cn("button", styles.button)}
                    to='https://app.reffo.deals/signup'
                >
                    Sign up
                </Link> 
            </div> 
            <br/>
            <div className={styles.note}>
              We will never spam you, and we'll always protect your private information.          
            </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
