import React from "react";
// import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Programs.module.sass";
import Slider from "react-slick";
import Icon from "../Icon";
import ScrollParallax from "../ScrollParallax";

const items = [
  {
    title: "Prevent food spoilage",
    url: "/class02-details",
    color: "#45B26B",
    image: "/images/content/appleEaten.svg",
    alt: "spoilage",
    content:
    "Did you know that it take take up to 25 years for a single head of lettuce to decompose in a landfill? When we waste food, it doesn't just go back to nature. It becomes a part of ugly landfills.",

  },
  {
    title: "Reduce greenhouse gases",
    url: "/class02-details",
    color: "#9757D7",
    image: "/images/content/overOrdering.svg",
    alt: "medal",
    content:
    "When exess food is unable to be used, it not only costs more gasoline to properly haul it away, it also creates greenhouse gases in our landfills when it turns into methane.",

  },
  {
    title: "Improve our community",
    url: "/class02-details",
    color: "#3772FF",
    image: "/images/content/weather.svg",
    alt: "lightning",
    content:
    "The restaurant industry only has profit margins of 3 - 5%. So helping them use the food they've already purchased and prepped helps the restaurants to stay in business, which supports our community!",

  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Programs = ({ classNameTitle, title, classNameSection, scrollToRef }) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={cn(classNameSection, styles.section)}>
      <div className={styles.anchor} ref={scrollToRef}></div>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={cn(styles.title, classNameTitle)}>{title}</div>
          <div className={styles.text}>
            <strong className={styles.green}>Only a 10% reduction</strong> in food waste would prevent {" "}
            <strong className={styles.black}>3.3 billion pounds</strong>  of organic matter from entering landfills and creating greenhouse gases.
          </div>
        </div>
        <div className={styles.wrap}>
          <Slider className="programs-slider" {...settings}>
            {items.map((x, index) => (
              <ScrollParallax className={styles.slide} key={index}>
                <div className={cn("programs-item", styles.item)}>
                  <div
                    className={styles.icon}
                    style={{ backgroundColor: x.color }}
                  >
                    <img src={x.image} alt={x.alt} />
                  </div>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                  {/* <Link
                    className={cn("button-stroke", styles.button)}
                    to={x.url}
                  >
                    Book a class
                  </Link> */}
                </div>
              </ScrollParallax>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Programs;
