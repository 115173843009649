import React from "react";
import cn from "classnames";
import styles from "./ValuePropsUsers.module.sass";
import ScrollParallax from "../ScrollParallax";
import Image from "../../components/Image";

const items = [
  {
    title: "No subscription, no hassle.",
    content:
      "With Reffo, there are no setup fees or membership charges. If you find a deal you like, snag it!",
    image:
    "/images/content/searchForDealsIcon.png",
    alt:
    "Search"
  },
  {  
    title: "Your deposit secures your deal.",
    content:
      "100% of your deposit is discounted from your bill. If you",
    image:
    "/images/content/restaurantCartoonIcon.png",
    alt:
    "Money goes to the restaurant"
  },
  {
    title: "Show your code, eat food!",
    content:
      "Arrive within the offer window, show your Reffo code, and enjoy your meal. That's it!",
      // "For every deal, Reffo keeps $1 + the transaction fee.the restaurant pays Reffo $1. Seriously, that's it.",
    image:
    "/images/content/relievedIcon.png",
    alt:
    "You get 100% back"
  },
];

const ValuePropsUsers = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage-small", styles.stage)}>
            Transparent Pricing
          </div>
          <div className={cn("h2", styles.title)}>
             If you're hungry for a good deal...
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div>
                <Image className={styles.icon}
                    srcSet={x.image}
                    srcSetDark={x.image}
                    alt={x.alt}
                />
              </div>
              <div className={styles.category}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ValuePropsUsers;
