import React from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import ContactForm from "../../../components/ContactForm";
import Image from "../../../components/Image";

const Hero = () => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <div className={styles.col}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/support.png 2x"
              srcSetDark="/images/content/support.png 2x"
              src="/images/content/support.png"
              srcDark="/images/content/support.png"
              alt="Download bg"
            />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.wrap}>
            <div className={cn("stage", styles.stage)}>
              WE'D LOVE TO HEAR FROM YOU
            </div>
            <h1 className={cn("h1", styles.title)}>
              Need something specific?
            </h1>
            <div className={styles.text}>
              You can always reach us at <a href={"https://twitter.com/reffodeals"} target="_blank" rel="noreferrer"><strong>@reffodeals</strong></a> on X, but if you want to send us a message here, that's cool too.
            </div>
            <ContactForm 
              className={styles.ContactForm}
              placeholder="Email"
              placeholderName="Name"
              placeholderMsg="What's on your mind?"
            />


            {/* <Subscription
              className={styles.subscription}
              placeholder="Enter your email"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
