import React, { useRef } from "react";
// import styles from "./Restaurants.module.sass";
import Hero from "./Hero";
// import Intro from "../../components/Intro";
import ValueProps from "../../components/ValueProps";
// import Program from "./Program";
import About from "./About";
// import Review from "../../components/Review";
// import Clients from "../../components/Clients";
// import RestHowItWorks from "../../components/RestHowItWorks";
import CostCalculator from "../../components/CostCalculator";
import RestaurantsCTA from "../../components/RestaurantsCTA";
// import Advantages from "../../components/Advantages";
// import Offer from "../../components/Offer";
// import Lifestyle from "./Lifestyle";
// import RestaurantSignupForm from "../../components/RestaurantSignupForm";

const Restaurants = () => {
  const scrollToRef = useRef(null);

  return (
    <>
      <Hero scrollToRef={scrollToRef} />
      <ValueProps className="section-pb" />
      <CostCalculator />
      <About />
      <RestaurantsCTA />

      {/* {/* <Lifestyle /> */}
      {/* <Intro scrollToRef={scrollToRef} />       */} 
      {/* <Program /> */}
      {/* <Clients /> */}
      {/* <RestHowItWorks /> */}
      {/* <RestaurantSignupForm /> */}
      {/* <Review className="section-pb64" /> */}
      {/* <Advantages />
      <Offer className="section" /> */}
    </>
  );
};

export default Restaurants;
