import React, { useState, useRef } from "react";
import cn from "classnames";
import styles from "./ContactForm.module.sass";
import Icon from "../Icon";
import emailjs from '@emailjs/browser';

const ContactForm = ({ className, placeholder, placeholderName, placeholderMsg }) => {
  const [contact_email, setEmail] = useState("");
  const [contact_name, setName] = useState("");
  const [contact_msg, setMsg] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ns6rl6g', 'template_byy0ugf', form.current, 'rKPnmClcp1UyZDaEO')
      .then((result) => {
          alert("success: " + contact_email + " : " + contact_name + " : " + contact_msg);
          console.log(result.text);
      }, (error) => {
          alert("error: " + contact_email + " : " + contact_name + " : " + contact_msg);
          console.log(error.text);
      });
  };

  return (
    <form
      ref={form}
      className={cn(styles.form, className)}
      action=""
      onSubmit={sendEmail}
    >
      <input
        className={styles.input}
        type="email"
        value={contact_email}
        onChange={(e) => setEmail(e.target.value)}
        name="contact_email"
        placeholder={placeholder}
        required
      />

      <input
        className={styles.input}
        type="text"
        value={contact_name}
        onChange={(e) => setName(e.target.value)}
        name="contact_name"
        placeholder={placeholderName}
        required
      />

      <textarea
        className={cn("textbox", styles.input)}
        type="text"
        value={contact_msg}
        onChange={(e) => setMsg(e.target.value)}
        name="contact_msg"
        placeholder={placeholderMsg}
        required
      />
      
      {/* <input
        className={styles.input}
        type="text"
        value={msg}
        onChange={(e) => setMsg(e.target.value)}
        name="msg"
        placeholder={placeholderMsg}
        required
      /> */}

          <div className={styles.btns}>
            <button className={cn("button", styles.button)} to="/class02-details">
              <span>Submit</span>
              <Icon name="arrow-right" size="10" />
            </button>
          </div>

      {/* <button className={styles.btn}>
        <Icon name="arrow-right" size="14" />
      </button> */}
    </form>
  );
};

export default ContactForm;
