import React from "react";
// import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Waitlist.module.sass";
import CostCalculator from "../../components/CostCalculator";
import Slider from "react-slick";
import Icon from "../Icon";
import { PopupButton } from '@typeform/embed-react'

const items = [
  {
    title: "General",
    url: "https://512oq7wpmxw.typeform.com/to/CeHJ90n3",
    color: "#45B26B",
    image: "/images/content/user.svg",
    alt: "users",
    content:
      "New features will be added regularly. These features will ",
      form: "https://512oq7wpmxw.typeform.com/to/CeHJ90n3",
  },
  {
    title: "First 50",
    url: "https://512oq7wpmxw.typeform.com/to/CeHJ90n3",
    color: "#9757D7",
    image: "/images/content/medal-1.svg",
    alt: "medal",
    content:
      "First 50 restaurants get all setup fees waved, access to new features first, and get life-time updates free.",
      form: "https://512oq7wpmxw.typeform.com/to/CeHJ90n3",
  },
  {
    title: "Nova",
    url: "https://512oq7wpmxw.typeform.com/to/CeHJ90n3",
    color: "#3772FF",
    image: "/images/content/lightning.svg",
    alt: "lightning",
    content:
      "If you sign up today, you'll get a lifetime discount on all of our paid plans up to 50% of the base price.",
    form: "https://512oq7wpmxw.typeform.com/to/CeHJ90n3",
  },
  {
    title: "Super nova",
    url: "https://512oq7wpmxw.typeform.com/to/CeHJ90n3",
    color: "#45B26B",
    image: "/images/content/user.svg",
    alt: "user",
    content:
      "Signing up today will give you a lifetime discount on all of our paid plans. Reach out to us for more info.",
      form: "https://512oq7wpmxw.typeform.com/to/CeHJ90n3",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Waitlists = ({ classNameTitle, title, classNameSection, scrollToRef }) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={cn(classNameSection, styles.section)}>
      <div className={styles.anchor} ref={scrollToRef}></div>
      <div className={cn("container", styles.container)}>

      <div
       className={styles.head}>
          <h2 className={cn("h2", styles.titleHead)}>This page is only for business owners, <br/>diners use Reffo for free!</h2>
          {/* <h2 className={cn("h2", styles.titleHead)}>The first 1,000 accounts <br></br>will receive a lifetime discount</h2> */}
          {/* <div className={styles.infotext}>
            Anyone who signs up for a paid account before we launch will receive a lifetime discount on all of our paid plans. The earlier you sign up, the bigger the discount. 
          </div> */}
          <div className={styles.infotext}>
            When a customer secures a deal with a deposit, 100% of that deposit is applied to the final bill when they redeem their Reffo. Restaurant costs are listed below.
          </div>
        </div>

        <div className={styles.top}>
          <div className={cn(styles.title, classNameTitle)}>{title}</div>
        </div>

        <h2>Setup Fees</h2>
        <div className={styles.info}>
          To help onboard restaurants and businesses so they can utilize the service to achieve the greatest impact, we charge a one-time, non-refundable setup fee of <strong className={styles.green}>$500</strong>.
           {/* This is waived for the first <strong className={styles.green}>50 restaurants that sign up *</strong>. */}
        </div>
        
        <h2>Monthly Fees</h2>
        <h4>Zero dollars. We will accept high fives though. </h4><br/><br/>

        <h2>Service Fees</h2>
        <div className={styles.infostart}>
         Reffo charges a flat service fee, depending on the offer type:
         </div>
        <h4>• <strong>Deals:</strong> $1 per deal</h4>
        <h4>• <strong>Experiences:</strong> $5 per deal</h4>
        <h4>• <strong>Birthday specials:</strong> free</h4>
        <br/>
        <div className={styles.info}>
          
          Stripe charges a transaction fee to deliver the deposit to the restaurant or business. This fee is covered by the business. The transaction fee is a percentage of the chosen deposit amount ($5, $10, $20, or $50). <br/><br/>
          
          The total fee is the sum of the Stripe transaction fee + the fixed Reffo service fee, which is automatically deducted from the restaurant or business payout. All fees are non-refundable.<br/><br/>
          
          To see an example, try the "Reffo Fee Calculator" below.<br/><br/>
        </div>

        <CostCalculator />
        
        <br/><br/>

        </div>
    </div>
  );
};

export default Waitlists;
