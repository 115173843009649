import React from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
// import ContactForm from "../../../components/ContactForm";
// import RestaurantSignupForm from "../../../components/RestaurantSignupForm";
import Image from "../../../components/Image";
import RestaurantInitForm from "../../../components/RestaurantInitForm";

const Hero = () => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <div className={styles.col}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/manChef.png 2x"
              srcSetDark="/images/content/manChef.png 2x"
              src="/images/content/manChef.png"
              srcDark="/images/content/manChef.png"
              alt="Download bg"
            />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.wrap}>
            <div className={cn("stage", styles.stage)}>
              Let's do this
            </div>
            <h1 className={cn("h1", styles.title)}>
              Start the application
            </h1>
            <div className={styles.text}>
             We love restaurants and we want to support your business and prevent food waste! That's why there are no monthly costs, and no commitments. If you have any questions, email us!
              <br/>
              <br/>
              More information about when service fees apply and how they are calculated can be found on our <a href="/pricing">Pricing</a> page.
            </div>
            <div className={cn("formFields", styles.formFields)} >
            <RestaurantInitForm 
                  placeholderBusinessName = "Business Name"
                  placeholderBusinessOwner = "Owner Name" 
                  placeholderOwnerPhone = "Owner's Phone Number"
                  placeholderOwnerEmail = "Owner's Email Address"
                  placeholderAddress1 = "Business Address"
                  placeholderAddress2 = "Apartment, Suite, etc."
                  placeholderCity = "City"
                  placeholderState = "State" 
                  placeholderZipcode = "Zip Code"
                />
            </div>


            {/* <Subscription
              className={styles.subscription}
              placeholder="Enter your email"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
