import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";

const Hero = ({ scrollToRef }) => {
    return (
        <div className={styles.hero}>
            <div className={cn("container", styles.container)}>
                <div className={styles.wrap}>
                    <div className={cn("stage", styles.stage)}>
                        COUPONS ARE SO LAST CENTURY
                    </div>
                    <h1 className={cn("h1", styles.title)}>
                        Flash deals for foodies
                        {/* We match hungry people with flash deals from local restaurants! */}
                    </h1>
                    <div className={styles.text}>
                        Find deals and exclusive experiences at restaurants nearby, then lock in the offer and reservation all at once. For free! 

                        {/* We put the power of notifications in your hands.
                        You determine the content, the audience, and the timing. */}
                    </div>
                    <div className={styles.btns}>
                        <Link
                            className={cn("button", styles.button)}
                            to='https://app.reffo.deals/'
                        >
                            Browse offers
                        </Link>
                        <Link
                            className={cn("button-stroke", styles.button)}
                            to="/restaurants"
                        >
                            More info for Restaurants
                        </Link>
                    </div>
                </div>
                <ScrollButton
                    onScroll={() =>
                        scrollToRef.current.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                    className={styles.scroll}
                />
                <div className={styles.gallery}>
                    <div className={styles.preview}>
                        <Image
                            srcSet="/images/content/reffoHeaderHero.png"
                            srcSetDark="/images/content/reffoHeaderHeroDark.png"
                            src="/images/content/heroHome.png"
                            srcDark="/images/content/heroHomeDark.png"
                            alt="Reffo is offer backwards"
                        />
                    </div>
                    <div className={styles.preview}>
                        <img
                            srcSet="/images/content/emptyBolt.png 2x"
                            src="/images/content/emptyBolt.png"
                            alt="Ball"
                        />
                    </div>
                    <div className={styles.preview}>
                        <img
                            srcSet="/images/content/emptyBolt.png 2x"
                            src="/images/content/emptyBolt.png"
                            alt="Bottle"
                        />
                    </div>
                    {/* <div className={styles.preview}>
                        <img
                            srcSet="/images/content/stickers/layer 3.png 2x"
                            src="/images/content/layer 3.png"
                            alt="Ball black"
                        />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Hero;
