import React, { useState } from "react";
import cn from "classnames";
import styles from "./Trainer.module.sass";
import Item from "./Item";

const items = [
  {
    name: "Mark Meechan",
    type: "Comedian",
    avatar: "/images/content/avatar-5.png",
    review: [
      {
        author: "Count Dankula",
        description: "Royal Edge Lord",
        logo: "/images/content/goldlines.svg",
        image: "https://d2eehagpk5cl65.cloudfront.net/img/q60/uploads/assets/mc/2018_03/Dankula.jpg",
        image2x: "https://d2eehagpk5cl65.cloudfront.net/img/q60/uploads/assets/mc/2018_03/Dankula.jpg",
        comment:
          "Notify has giving me an insurance against big tech sensorship that gives me the confidence to speak my mind and not worry about being deplatformed.",
      },
    ],
  },
  {
    name: "Devon Tracey",
    type: "streamer",
    avatar: "/images/content/avatar-1.png",
    review: [
      {
        author: "Devon Tracey",
        description: "streamer",
        logo: "/images/content/velocity9.svg",
        image: "https://cdnb.artstation.com/p/assets/images/images/058/133/719/large/devon-tracey-chess2.jpg?1673459493",
        image2x: "https://cdnb.artstation.com/p/assets/images/images/058/133/719/large/devon-tracey-chess2.jpg?1673459493",
        comment:
          "Youtube continually demoted my videos and unsubscribed my viewers. Notify has given me the ability to reach my audience without the need to restart my channel every few months.",
      },
    ],
  },
  {
    name: "RFK Jr",
    type: "Presidential cantidate for 2024",
    avatar: "/images/content/avatar-2.png",
    review: [
      {
        author: "RFK Jr",
        description: "Presidential cantidate for 2024",
        logo: "/images/content/goldlines.svg",
        image: "https://images.newrepublic.com/b34b022694f90dea17253dcead05776e0eb30b9f.jpeg?auto=compress&w=768&h=undefined&ar=3%3A2&fit=crop&crop=faces&q=65&fm=jpg&ixlib=react-9.0.2",
        image2x: "https://images.newrepublic.com/b34b022694f90dea17253dcead05776e0eb30b9f.jpeg?auto=compress&w=768&h=undefined&ar=3%3A2&fit=crop&crop=faces&q=65&fm=jpg&ixlib=react-9.0.2",
        comment:
          "The electorate in the US is completely divided. Notify is the only platform that allows me to reach both sides of the political spectrum.",
      },
    ],
  },
  {
    name: "Joe Rogan",
    type: "Horse Paste Enthusiast",
    avatar: "/images/content/avatar-3.png",
    review: [
      {
        author: "Joe Rogan",
        description: "Horse Paste Enthusiast",
        logo: "/images/content/goldlines.svg",
        image: "https://static01.nyt.com/images/2021/09/01/multimedia/01virus-briefing-joe-rogan-01/01virus-briefing-joe-rogan-01-superJumbo.jpg",
        image2x: "https://static01.nyt.com/images/2021/09/01/multimedia/01virus-briefing-joe-rogan-01/01virus-briefing-joe-rogan-01-superJumbo.jpg",
        comment:
          "After CNN exposed me for using horse dewormer, the powers that be tried to cancel my podcast. Now I can say what ever I want without the fear of a bunch of mids on twitter trying to cancel me.",
      },
    ],
  },
];

const Trainer = ({ className }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className={cn(styles.section, className)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.list}>
          {items[activeIndex].review.map((x, index) => (
            <Item item={x} key={index} />
          ))}
        </div>
        <div className={styles.nav}>
          {items.map((x, index) => (
            <div
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              <div className={styles.avatar}>
                <img src={x.avatar} alt="Avatar" />
              </div>
              <div className={styles.details}>
                <div className={styles.man}>{x.name}</div>
                <div className={styles.position}>{x.type}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Trainer;
