import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Intro.module.sass";
import ScrollParallax from "../ScrollParallax";

const Intro = ({ scrollToRef }) => {
  return (
    <div className={styles.section} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/earth.png 2x"
              src="/images/earth.png"
              alt="Bag"
            />
          </ScrollParallax>
          {/* <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/gloves@2x.png 2x"
              src="/images/content/gloves.png"
              alt="Gloves"
            />
          </ScrollParallax>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/bottle-1@2x.png 2x"
              src="/images/content/bottle-1.png"
              alt="Bottle"
            />
          </ScrollParallax> */}
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h1", styles.title)}>
            Good for the planet
          </h2>
          <div className={styles.text}>
            Dining during off-peak hours means restaurants waste less food, so you can feel good about helping the planet and local businesses!
          </div>
          <Link className={cn("button", styles.button)} to="/food-waste">
            Additional resources
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Intro;
